/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const TeamGrid = ({ items }) => (
	<div
		sx={{
			display: "flex",
			flexDirection: ["column", "row", "row"],
			alignItems: ["center", "flex-start", "flex-start"],
			justifyContent: "space-evenly",
		}}
	>
		{items.map((item) => (
			<div
				sx={{
					width: ["100%", "30%", "30%"],
					mb: [3, "0px", "0px"],
					"&:last-of-type": {
						mb: "0px",
					},
					textAlign: "center",
				}}
				key={item.fullName}
			>
				<div>
					<PreviewCompatibleImage
						sxProp={{
							borderRadius: "100%",
							mb: 2,
							width: 12,
							mx: "auto",
						}}
						title={item.imageObject.title}
						alt={item.imageObject.alt}
						childImageSharp={item.imageObject.image.childImageSharp}
						image={item.imageObject.image}
					/>

					<h4
						sx={{
							variant: "text.heading",
							fontSize: [3, 4, 4],
							mt: 3,
							mb: "0px",
						}}
					>
						{item.fullName}
					</h4>
					<h5
						sx={{
							fontSize: [3, 4, 4],
							fontWeight: "body",
							mt: 2,
							mb: "0px",
						}}
					>
						{item.position}
					</h5>
					{!!item.description && (
						<p
							sx={{
								variant: "text.body",
								fontSize: [2, 3, 3],
								mt: 2,
								mb: "0px",
							}}
						>
							{item.description}
						</p>
					)}
				</div>
			</div>
		))}
	</div>
);

TeamGrid.propTypes = {
	gridItems: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.object,
			text: PropTypes.string,
		})
	),
};

export default TeamGrid;
