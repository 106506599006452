/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const Benefits = ({ gridItems }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["column", "column", "row"],
      justifyContent: "space-between"
    }}
  >
    {gridItems.map(item => (
      <div
        key={item.text}
        sx={{
          width: ["100%", "100%", "31.5%"],
          mb: [3, 4, "0px"],
          bg: "muted",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <img
          sx={{
            alignSelf: "center",
            width: 4,
            height: 5,
            pt: [2, 4, 4]
          }}
          title={item.imageObject.title}
          alt={item.imageObject.alt}
          src={item.imageObject.image.publicURL}
        />
        <h4
          sx={{
            alignSelf: "center",
            my: [2, 3, 3],
            variant: "text.body",
            fontSize: [3, 4, 4],
            fontWeight: "lightbold"
          }}
        >
          {item.heading}
        </h4>
        <p
          sx={{
            mt: "0px",
            mx: [3, 4, 4],
            mb: [2, 4, 4],
            variant: "text.body",
            fontSize: [2, 3, 3],
          }}
        >
          {item.description}
        </p>
      </div>
    ))}
  </div>
);

Benefits.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      text: PropTypes.string
    })
  )
};

export default Benefits;
