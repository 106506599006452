/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const Steps = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <div
          key={item.heading}
          sx={{
            display: "flex",
            verticalAlign: "top",
            flexDirection: ["column", "row", "row"]
          }}
        >
          <div
            sx={{
              color: "primary",
              fontSize: 12,
              fontWeight: "bold",
              minWidth: "100px"
            }}
          >
            <p
              sx={{
                textAlign: ["center", "start", "start"],
                lineHeight: 0.8,
                my: [2, "0px", "0px"]
              }}
            >
              {index + 1}.
            </p>
          </div>
          <div>
            <h4
              sx={{
                my: [2, "0px", "0px"],
                variant: "text.body",
                fontSize: [3, 4, 4],
                fontWeight: "lightbold",
                textAlign: ["center", "left", "left"]
              }}
            >
              {item.heading}
            </h4>
            <p
              sx={{
                mt: 2,
                mb: [3, 4, 4],
                variant: "text.body",
                fontSize: [2, 3, 3]
              }}
            >
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

Steps.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      description: PropTypes.string,
      heading: PropTypes.string
    })
  )
};

export default Steps;
