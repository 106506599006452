/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { IndexPageTemplate } from "../templates/index-page"
import Layout from "../components/Layout";

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        herosection={frontmatter.herosection}
        mainpitch={frontmatter.mainpitch}
        benefits={frontmatter.benefits}
        processes={frontmatter.processes}
        team={frontmatter.team || null}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          herosection {
            title
            subtitle
            imageObject {
              title
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          mainpitch {
            title
            description {
              paragraph
            }
          }
          benefits {
            heading
            blurbs {
              heading
              description
              imageObject {
                title
                alt
                image {
                  publicURL
                }
              }
            }
          }
          processes {
            heading
            steps {
              heading
              description
            }
          }
          team {
            title
            teamMember {
              fullName
              position
              description
              imageObject {
                title
                alt
                image {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
