/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import BackgroundImage from "gatsby-background-image";

import Benefits from "../components/Benefits";
import Team from "../components/Team";
import Steps from "../components/Steps";
//import TerminButton from "../components/TerminButton";

export const IndexPageTemplate = ({
	herosection,
	mainpitch,
	benefits,
	processes,
	team,
}) => (
	<React.Fragment>
		<section
			sx={{
				bg: "muted",
				variant: "spacing",
			}}
		>
			<BackgroundImage
				sx={{
					height: ["40vh", "60vh", "60vh"],
					width: "100%",
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "none",
				}}
				fluid={
					!!herosection.imageObject.image.childImageSharp
						? herosection.imageObject.image.childImageSharp.fluid
						: herosection.imageObject.image
				}
			>
				<div
					sx={{
						width: "100%",
						height: "100%",
						bg: "rgba(0, 0, 0, 0.4)",
						display: "flex",
						alignItems: ["center", "flex-end", "flex-end"],
					}}
				>
					<h1
						sx={{
							px: [2, 4, 4],
							my: "0px",
							fontSize: [5, 9, 9],
							fontWeight: "bold",
							color: "background",
							width: ["100%", "70%", "50%"],
							textAlign: ["center", "left", "left"],
						}}
					>
						{herosection.title}
					</h1>
				</div>
			</BackgroundImage>
			<h2
				sx={{
					variant: "text.body",
					px: [2, 4, 4],
					my: 0,
					py: [1, 3, 3],
					fontSize: [2, 4, 4],
					maxWidth: "1000px",
				}}
			>
				{herosection.subtitle}
			</h2>
		</section>

		<section
			sx={{
				mx: [2, 4, 6],
				mb: [5, 6, 6],
			}}
		>
			<h2
				sx={{
					variant: "text.heading",
					mt: "0px",
					mb: [3, 4, 4],
					lineHeight: [1, 1.5, 1.5],
					textAlign: ["center", "left", "left"],
				}}
			>
				<span sx={{ fontSize: [7, 9, 9] }}>83% </span>
				<br sx={{ display: ["block", "none", "none"] }} />
				{mainpitch.title}
			</h2>
			<div>
				{mainpitch.description.map((el) => (
					<p sx={{ variant: "text.body", fontSize: [2, 3, 3] }}>
						{el.paragraph}
					</p>
				))}
			</div>
		</section>

		<section sx={{ px: [2, 4, 4], mb: [5, 6, 6] }}>
			<h2
				sx={{
					fontSize: [7, 9, 9],
					mt: "0px",
					mb: [3, 4, 4],
					textAlign: ["center", "left", "left"],
				}}
			>
				<span id="vorteile" sx={{ position: "relative", bottom: 5 }} />
				{benefits.heading}
			</h2>
			{benefits.description && <h3>{benefits.description}</h3>}
			<Benefits gridItems={benefits.blurbs} />
		</section>

		<section sx={{ mx: [2, 4, 6], mb: [5, 6, 6] }}>
			<h2
				sx={{
					fontSize: [7, 9, 9],
					mt: "0px",
					mb: [3, 4, 4],
					textAlign: ["center", "left", "left"],
				}}
			>
				<span id="leistung" sx={{ position: "relative", bottom: 5 }} />
				{processes.heading}
			</h2>
			{processes.description && (
				<h3 sx={{ variant: "text.body", fontSize: [2, 3, 3] }}>
					{processes.description}
				</h3>
			)}

			<Steps items={processes.steps} />
		</section>

		<section sx={{ px: [2, 4, 4], mb: [5, 6, 6] }}>
			<h2
				sx={{
					fontSize: [7, 9, 9],
					mt: "0px",
					mb: [3, 4, 4],
					textAlign: ["center", "left", "left"],
				}}
			>
				<span id="team" sx={{ position: "relative", bottom: 5 }} />
				{team.title}
			</h2>
			{team.description && <h3>{team.description}</h3>}

			<Team items={team.teamMember} />
		</section>
	</React.Fragment>
);
